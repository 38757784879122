@import "~bourbon/app/assets/stylesheets/bourbon";
@import "partials/_variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-touchspin/dist/jquery.bootstrap-touchspin.min.css";
@import "partials/_mixins";
@import "partials/_fonts";
@import "partials/_commons";
@import "components/alert";
@import "components/drop-down";
@import "components/search-widget";
@import "components/mainmenu";
@import "components/checkout";
@import "components/customer";
@import "components/forgotten-password";
@import "components/renew-password";
@import "components/imageslider";
@import "components/featuredproducts";
@import "components/custom-text";
@import "components/categories";
@import "components/products";
@import "components/cart";
@import "components/block-reassurance";
@import "components/quickview";
@import "components/stores";
@import "components/footer";
@import "components/contact";
@import "components/errors";
@import "components/customization-modal";
@import "components/sitemap";
@import "components/productrange";

/*** HEADER ***/
#header {
  background-image:url(../img/header_bg2.png);
  background-size:100%;
  color: #fff;
  .logo {
    max-width: 100%;
  }
  a {
    color:#fff;
    font-size:13px;
    &:hover {
      text-decoration: none;
      color: $brand-primary;
    }
    &.icon {
      &:hover {
        color:#fff;
      }
    }
  }
  .header-h1 {
    text-align:center;
    font-size:9px;
    color:#494a50;
    margin:0;
    padding-top:3px;
  }
  .header-nav {
    display:flex;
    align-items:center;
    height:30px;
    //background:#3e4590;
    max-height: 30px;
    #menu-icon {
      vertical-align: middle;
      cursor: pointer;
      margin-left: 1rem;
      .material-icons {
        line-height: 50px;
      }
    }
    .right-nav {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-end;
    }
    .currency-selector {
      margin-top: 0.9375rem;
      margin-left: 0.9375rem;
      white-space: nowrap;
    }
    .user-info {
      margin-left: 2.5rem;
      margin-top: 0.9375rem;
      text-align: right;
      white-space: nowrap;
      .account {
        margin-left: $small-space;
      }
    }
    .language-selector {
      margin-top: 0.9375rem;
      white-space: nowrap;
    }
    .cart-preview {
      &.active {
        background: $brand-primary;
        a, i {
          color: white;
        }
      }
      .shopping-cart {
        vertical-align: middle;
        color: $gray;
      }
      .body {
        display: none;
      }
    }
    .blockcart {
      background: $gray-light;
      height: 3rem;
      padding: 0.75rem;
      margin-left: 0.9375rem;
      text-align: center;
      white-space: nowrap;
      a {
        color: $gray;
        &:hover {
          color: $brand-primary;
        }
      }
      &.active {
        a:hover {
          color: white;
        }
      }
      .header {
        margin-top: 0.125rem;
      }
    }
    //#contact-link {
    //  margin-top: 0.9375rem;
    //}
    .material-icons {
      line-height: inherit;
      &.expand-more {
        margin-left: -0.375rem;
      }
    }
  }
  .header-top {
    padding:11px 0;

    .header-flex {
      display:flex;
      align-items:center;
    }
    > .container {
      position: relative;
    }
    .menu {
      > ul {
        > li {
          float: left;
        }
      }
      padding-left: 15px;
      margin-bottom: 0.375rem;
    }
    .position-static {
      position: static;
    }
    a[data-depth="0"] {
      color: $gray;
      text-transform: uppercase;
    }
  }
  .top-menu-link {
    margin-left: $medium-space;
  }
}

.popover {
  font-family: inherit;
}
/*** WRAPPER ***/
#wrapper {
  background: $gray-light;
  box-shadow: inset 0 2px 5px 0 rgba(0,0,0,0.11);
  //padding-top: 1.563rem;
  padding-top: 37px;
  .banner {
    margin-bottom: 1.5rem;
    display: block;
    img {
      box-shadow: 1px 1px 7px 0 rgba(0, 0, 0, 0.15);
    }
  }
  .breadcrumb {
    background: transparent;
    padding: 0;
    &[data-depth="1"] {
      //display: none;
    }
    ol {
      padding-left: 0;
      margin-bottom: 0;
    }
    //li {
    //  display: inline;
    //  &::after {
    //    content: "/";
    //    color: $gray;
    //    margin: 0.3125rem;
    //  }
    //  &:last-child {
    //    content: "/";
    //    color: $gray;
    //    margin: 0;
    //    &::after {
    //      content: "";
    //    }
    //  }
    //  a {
    //    color: $gray-darker;
    //  }
    //}
  }
}
/*** MAIN ***/
#main {
  .page-header {
    margin-bottom: 1.563rem;
  }
  .page-content {
    margin-bottom: 1.563rem;
    h6 {
      margin-bottom: 1.125rem;
    }
    #notifications {
      margin-left: -15px;
      margin-right: -15px;
    }
  }
  .page-footer {
    margin-bottom: 1.563rem;
  }
}
#notifications {
  ul {
    margin-bottom: 0;
  }
}
/*** FOOTER ***/
#footer {
  padding-top: 2.5rem;
  background: #eff1f7;
}

/*** Responsive part ***/
@include media-breakpoint-down(sm) {
  #wrapper {
    box-shadow: none;
  }
  #checkout-cart-summary {
    float: none;
    width: 100%;
    margin-top: 1rem;
  }
  #header {
    //background: $gray-light;
    .header-nav {
      //background: white;
      //margin-bottom: 0.625rem;
      //color: $gray-darker;
      .top-logo {
        line-height: 50px;
        vertical-align: middle;
        width: 200px;
        margin: 0 auto;
        a {
          img {
            max-height: 50px;
            max-width: 100%;
          }
        }
      }
      .right-nav {
        flex-direction: column;
      }
      .user-info {
        text-align: left;
        margin-left: 0;
        .logged {
          color: $brand-primary;
        }
      }
      .blockcart {
        margin-left: 0;
        background: inherit;
        &.active {
          margin-left: 0.5rem;
        }
        &.inactive {
          .cart-products-count {
            display: none;
          }
        }
      }
    }
    //.header-top {
    //  background: $gray-light;
    //  padding-bottom: 0;
    //  a[data-depth="0"] {
    //    color: $gray-darker;
    //  }
    //}
  }
  section.checkout-step {
    width: 100%;
  }
  .default-input {
    min-width: 100%;
  }
  label {
    clear: both;
  }
  #products .product-miniature,
  .featured-products .product-miniature {
    //margin: 0 auto;
  }
  .block-contact {
    padding-left: 0.9375rem;
    border: none;
  }
  .menu,
  .dropdown-item {
    padding-left: 0;
  }
  #footer {
    padding-top: 0.5rem;
  }
}
@include media-breakpoint-down(md) {
  .container {
    max-width: 100%;
  }
  #header .logo {
    width: auto;
  }
  #products .product-miniature,
  .featured-products .product-miniature {
    //margin: 0 auto;
  }
  .sub-menu {
    left: 0;
    min-width: 100%;
  }
  #blockcart-modal .product-image {
    width: 100%;
    display: block;
    max-width: 250px;
    margin: 0 auto 0.9375rem;
  }
  #blockcart-modal .cart-content {
    padding-left: 0;
  }
  #blockcart-modal .product-name,
  #product-availability {
    margin-top: $small-space;
  }
  #search_filters .facet .facet-label {
    text-align: left;
  }
  .block-category .category-cover {
    position: relative;
    text-align: center;
  }
  .block-category {
    padding-bottom: 0;
  }
}

@include media-breakpoint-down(xs) {
  #left-column, #content-wrapper {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

/* === PT Sans - regular */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  src: url("./font/PtSans-Regular.eot");
  src: local("PT Sans"),
  local("PTSans-Regular"),
  url("./font/PtSans-Regular.eot") format("embedded-opentype"),
  url("./font/PtSans-Regular.woff2") format("woff2"),
  url("./font/PtSans-Regular.woff") format("woff"),
  url("./font/PtSans-Regular.ttf") format("truetype"),
  url("./font/PtSans-Regular.svg") format("svg");
}

/* === PT Sans - 700 */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  src: url("./font/PtSans-Bold.eot");
  src: local("PT Sans Bold"),
  local("PTSans-Bold"),
  url("./font/PtSans-Bold.eot") format("embedded-opentype"),
  url("./font/PtSans-Bold.woff2") format("woff2"),
  url("./font/PtSans-Bold.woff") format("woff"),
  url("./font/PtSans-Bold.ttf") format("truetype"),
  url("./font/PtSans-Bold.svg") format("svg");
}

@import "custom";
@import "responsive";
@import "fixes";