#block-reassurance{
  .block-reassurance-item {
    padding:11px 0;
    display: flex;
    align-items:center;

    img {
      margin-right:10px;
    }

    span.h6 {
      color:$brand-primary;
      font-size:14px;
      font-weight:800;
      margin-bottom:0;
    }
  }
}
