#products,.featured-products,.product-accessories,.product-miniature, .viewed-products, .similar-products  {
  .products {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  article.product-miniature {
    padding-bottom:20px;
  }
  .product-thumbnail {
    display: block;
    text-align:center;
    transition:.2s;
  }
  .thumbnail-container {
    position: relative;
    background: white;
    overflow:hidden;
    border:1px solid #ccc;
    border-radius:3px;
  }
  .products-section-title {
    text-align: center;
    margin-bottom: 1.5rem;
  }
  .product-title {
    margin-top: 7px;
    text-transform:none;
    max-height:58px;
    height:58px;
    padding:0 10px;
    overflow:hidden;
    a {
      color: $brand-secondary;
      font-size: 15px;
      text-decoration: none;
      text-align: center;
      font-weight: 400;
    }
  }
  .product-price-and-shipping {
    color: $brand-secondary;
    padding:10px;
    text-align:center;
    height:46px;

    .price-col {
      display:flex;
      flex-direction:column;
      line-height:1.2;
    }
  }
  .variant-links {
    position: relative;
    text-align: center;
    width: 100%;
    top: -0.25em;
    padding-top: 0.1875rem;
    min-height: 2.5rem;
    background: white;
  }
  .highlighted-informations {
    position: relative;
    bottom: -4.25rem;
    z-index: 0;
    text-align: center;
    height:0;
    color:#fff;
    transition: bottom .3s;
    a.quick-view-p {
      color:#fff;
      font-size:18px;
      font-weight:600;
      letter-spacing:0.5px;
      img {
        margin-bottom:8px;
      }
    }
  }

  .product-description {
    position: relative;
    z-index: 1;
    background: white;
    //bottom: -2px;
    //height: 108px;
    overflow:hidden;
    transition:.2s;

    .detalii-produs {
      background:$brand-primary;
      border-bottom-left-radius:2px;
      border-bottom-right-radius:2px;
      letter-spacing:0;
      text-align: center;
      margin-top:8px;
      a {
        display: block;
        padding:7px;
        color:#fff;
        font-size:14px;
      }
    }
  }
  img {
    //margin-left: 4px;
  }

  .comments_note {
    text-align: center;
    color: $gray;
  }
  .regular-price {
    color: $brand-secondary;
    font-size:14px;
    text-decoration: line-through;
  }
  .price {
    font-size:18px;
    font-weight:600;
    color:$brand-primary;
  }
  .count {
    color: $gray;
    font-weight: 700;
    position: relative;
    bottom: 0.5rem;
  }
  .all-product-link {
    clear: both;
    color: $gray;
    font-weight: 700;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .layer {
    display:none;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: rgba(62, 69, 144, 0.7);
    position: absolute;
    left: 0;
    top: 0;
    text-align: center;
    cursor: pointer;
    z-index:2;
    pointer-events: none;
    transition:1s;
    border-radius:5px;
  }

  .thumbnail-container {
    @media (min-width: 992px) {
      &:hover {
        .product-thumbnail, .product-description {
          position: relative;
          margin-top: -40px;
          transition: .2s;
        }

        .product-thumbnail {
          margin-bottom: 40px;
        }

        .highlighted-informations {
          bottom: 0 !important;
          text-align: left;
          padding: 0 5px;
          span {
            color: $brand-secondary;
            font-size: 12px;
            line-height: 0;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {

}

@include media-breakpoint-down(xs) {
  #products {
    .pret-indisponibil {
      margin-left:11px;
    }
  }
}


.similar-products, .product-accessories {
  article.product-miniature {
    padding-bottom:0!important;
  }
}
