.cart-grid {
  margin-bottom: 1rem;
}
.cart-items {
  margin-bottom: 0;
}
.cart-item {
  padding: 1rem 0;
  border-bottom:1px dashed #d5d4d4;

  &:last-child {
    border:none;
  }
}
.cart-summary-line {
  @include clearfix;
  clear: both;
  .label {
    padding-left: 0;
    font-weight: normal;
    white-space: inherit;
  }
  .value {
    color: $gray-darker;
    float: right;
  }
  &.cart-summary-subtotals {
    .label,
    .value {
      font-weight: normal;
    }
  }
}
/** CART BODY **/
.cart-grid-body {
  .product-price {
    font-size:16px;
    display:block;
    margin-top:5px;
  }
  .regular-price {
    font-size:14px;
  }
  .qty {
    //margin-left:10px;
    padding:0;
  }
  .product-line-info {
    span.label, span.value {
      font-size:14px;
    }

    .price {
      font-weight:400;
    }
    .current-price {
      font-size:17px;
      font-weight:400;
    }

    &.has-discount {
      .current-price {
        color:#c02c2c!important;
      }
    }
  }
  a.label {
    &:hover {
      color: $brand-primary;
    }
  }
  .card-block {
    padding: 1rem;
    h1 {
      margin-bottom: 0;
    }
  }
  .cart-overview {
    padding: 1rem;
  }
  margin-bottom: 0.75rem;
}
/** CART RIGHT **/
.cart-grid-right {
  .promo-discounts {
    margin-bottom: 0;
    .cart-summary-line {
      .label {
        color: $gray;
        .code {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}
.block-promo {
  .promo-code {
    padding: 1.60rem;
    background: $gray-light;
    .alert-danger {
      position: relative;
      margin-top: 1.25rem;
      background: $brand-danger;
      color: white;
      display: none;
      &::after {
        bottom: 100%;
        left: 10%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-bottom-color:  $brand-danger;
        border-width: 10px;
        margin-left: -10px;
      }
    }
  }
  .promo-input {
    color: $gray-darker;
    border: $gray 1px solid;
    height: 2.5rem;
    text-indent: 0.625rem;
    width: 60%;
    + button {
      margin-top: -4px;
      text-transform: capitalize;
    }
  }
  .cart-summary-line .label,
  .promo-name {
    color: $brand-warning;
    font-weight: 600;
    a {
      font-weight: normal;
      color: $gray-darker;
      display: inline-block;
    }
  }
  .promo-code-button {
    padding-left: 1.25rem;
    margin-bottom: 1.25rem;
    display: inline-block;
  }
  &.promo-highlighted {
    padding: 1.25rem;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}
/** CONTENT LEFT **/
.product-line-grid-left {
  img {
    max-width: 100%;
  }
}
/** CONTENT BODY **/
.product-line-grid-body {
  > .product-line-info {
    > .label {
      font-size:15px;
      padding: 0;
      line-height: inherit;
      text-align: left;
      white-space: inherit;
    }
    > .out-of-stock {
      color: red;
    }
    > .available {
      color: $brand-success;
    }
    > .unit-price-cart {
      padding-left: 0.3125rem;
      font-size: 0.875rem;
      color: $gray;
    }
  }
}
/** CONTENT LEFT **/
.product-line-grid-right {
  .cart-line-product-actions,
  .product-price {
    color: $gray-darker;
    //line-height: 36px;
    .remove-from-cart {
      color: $gray-darker;
      display: inline-block;
      //margin-top: 0.3125rem;
      margin-top: -4px;
    }
  }
}

/*** Responsive part ***/
@include media-breakpoint-down(sm) {
  .product-line-grid-body {
    margin-bottom: 1rem;
  }
}

@include media-breakpoint-down(xs) {
  .cart-items {
    padding: 1rem 0;
  }
  .cart-item {
    &:last-child {
      border-bottom: 0;
    }
  }
  .cart-grid-body {
    .cart-overview {
      padding: 0;
    }
    .no-items {
      padding: 1rem;
      display: inline-block;
    }
  }
  .product-line-grid-left {
    padding-right: 0 !important;
  }
}

@media (max-width: 360px) {
  .product-line-grid-right {
    .qty {
      width: 100%;
    }
    .price {
      width: 100%;
    }
  }
}


body#cart {
  .qty {
    //display:flex;
    //flex-wrap:wrap;
    //align-items:center;
    //justify-content:flex-end;
    //margin-right:11px;
    background:#e2e2e2;
    border-radius:10px;
    width:auto;

    .btn {
      padding:6px;
      background: #d0d0d0;
      color:#494949;
      margin-top:13px;
    }

    .input-group {
      display:flex;
      width:auto;
      box-shadow:none;
      background:#d0d0d0;
      border-radius:10px;
      .input-group-btn {

        width:100%;
      }
    }
  }

  .js-cart-line-product-quantity {
    color: #494949;
    background-color: #e2e2e2!important;
    height: 50px;
    width: 58px!important;
    text-align:center;
    border:none;
    font-size:18px;
    padding:0;

    .input-group-btn {
      width: auto;
      .btn {
        padding: 0.5rem 0.6875rem;
        i {
          font-size: 1rem;
          top: 0.125rem;
          left: 0.1875rem;
        }
      }
    }
  }
}