body {
  font-family:'PT Sans', sans-serif;
  //font-family:'Lato', sans-serif;
  background: #fff;

  a:focus {
    text-decoration:none!important;
    outline:none;
  }

  button:focus {
    outline:none!important;
  }

  input:focus {
    outline:none!important;
  }

  @media (min-width: 1200px) {
    .container {
      width: 1250px;
      //width: 1140px;
      max-width: 100%;
    }
  }
}

#wrapper {
  box-shadow:none!important;
  background: #fff!important;
}

#menu-icon {
  cursor:pointer;
  color:$brand-primary;
}

#header-info {
  display:flex;
  align-items:center;

  #schedule {
    display:flex;
    align-items:center;
    margin-top:1px;
    padding-right:15px;
    i {
      margin-right:5px;
    }
  }

  #contact-links {
    display:flex;
    align-items:center;
    i {
      margin-right:5px;
    }
  }

}

.topNav {
  ul {
    margin-bottom:0;
    li {
      float:left;
      margin-left:3px;
      &:after {
        content:" | ";
        color:#fff;
      }
      &:last-child {
        &:after {
          content:"";
        }
      }
    }
  }
}


#sec_search_widget {
  float:left;
  position:relative;
  max-height:39px;
  width:100%;
  form {
    position:relative;
    z-index:9;
  }
  input[type=search] {
    color:#fff;
    width:100%;
    padding:3px 12px;
    font-size:14px;
    line-height:2;
    max-height:39px;
    background:rgba(255,255,255,.1);
    border:1px solid transparent;
    border-radius:3px;
    &:focus {
      border:1px solid #a9aaae;
      color:#fff;
      &~button {
        top:4px;
        background:rgba(255,155,0,1);
      }
    }
  }
  button {
    position:absolute;
    right:4px;
    top:4px;
    max-height:28px;
    background:rgba(255,155,0,.8);
    border:none;
    height:28px;
    width:28px;
    border-radius:3px;
    color:#fff;
    cursor:pointer;

    .search-icon {
      width:15px;
      height:15px;
      display:inline-block;
    }
  }

  .searchresult-wrap {
    z-index:6;
  }

  .searchresult {
    width:100%;
    right:0;
    max-height:360px;

    .ui-autocomplete {
      //background: rgba(255, 255, 255, .7);

      .product-info {
        padding:10px;
        a {
          font-size:16px;
          text-transform:none;
          color:$brand-secondary;
        }
        p {
          font-size:12px;
          color:$brand-primary;
        }
        span.no-reduction {
          font-size:16px;
          color:$brand-secondary;
        }
      }
    }

    .nano-pane {
      background:rgba(205, 105, 0, 0.4);
      .nano-slider {
        background:$brand-primary;
      }
    }
  }

  ::placeholder {
    color:#fff;
  }
}

#account, #shoppingcart {
  float:right;
  display:flex;
  position:relative;
  align-items:center;
  font-size:14px;
  height:40px;
  //width:50%;
}

#account {
  a {
    color:#fff;
  }
  i {
    font-size:20px;
    margin-right:10px;
  }

  .account-info {
   line-height:1;
  }

  #account-expanded {
    position: absolute;
    top: 40px;
    //left:0;
    right:-115px;
    width: 255px;
    z-index: 100 !important;

    .account-expanded-padd {
      background:#fff;
      color:#353535;
      margin-top:10px;
      box-shadow:1px 1px 3px 1px rgba(0, 0, 0, 0.22);

      &::before {
        content:"";
        position:absolute;
        top:0;
        left:19.5%;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 7.5px 10px 7.5px;
        border-color: transparent transparent #ffffff transparent;
        line-height: 0px;
        _border-color: #000000 #000000 #007bff #000000;
        _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
      }
    }

    .account-top {
      padding:10px;

      .account-name {
        font-weight:500;
        border-left:2px solid $brand-primary;
        padding-left:10px;
      }
    }

    .account-middle {
      padding:10px;
      a {
        font-size:14px;
        color:#000;
      }
    }

    .account-bottom {
      padding:10px;
      background:#eff1f7;
      display:flex;
      align-items:center;
      justify-content:center;

      a.btn {
        background:$brand-primary;
        box-shadow:none;
        color:#fff!important;
        text-transform:none;
        font-weight:400;
        width:100%;
        padding:5px;
        margin:0 5px;
        border-radius:3px;
      }
    }
  }
}

#shoppingcart {
  font-size:14px;
  color:#fff;
  margin-left:20px;
  a {
    color:#fff;
  }
  i {
    font-size:20px;
    margin-right:10px;
    margin-top:3px;
  }

  .cart-info {
    position:relative;

    span.cart-products-count {
      background:$brand-primary;
      padding:2px;
      border-radius:3px;
      position:absolute;
      left:-18px;
      top:-3px;
      font-size:12px;
      color:#fff!important;
    }
  }

  .cart-icon-wrapper {
    position: absolute;
    right:4px;
    top:-2px;
    .cart-icon {
      display:block;
      width:33px;
      height:33px;
      border:1px solid #fff;
      border-radius:30px;
      background: url(../img/cos.svg);
      background-size:26px 21px;
      background-repeat:no-repeat;
      background-position:0 6px;
    }
  }

  .cart-preview {
    position:relative;
    display: flex;
    width:100%;
    line-height:1;
  }

  #cart-expanded {
    position:absolute;
    top:40px;
    right:-1px;
    width:255px;
    z-index:100;

    .cart-expanded-padd {
      background:#fff;
      color:#353535;
      margin-top:10px;
      box-shadow:1px 1px 3px 1px rgba(0, 0, 0, 0.22);

      &::before {
        content:"";
        position:absolute;
        top:0;
        left:80%;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 7.5px 10px 7.5px;
        border-color: transparent transparent #ffffff transparent;
        line-height: 0px;
        _border-color: #000000 #000000 #007bff #000000;
        _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
      }
    }



    .cart-top {
      max-height:200px;
      padding:10px;
      overflow:hidden;

      ul.product-line-list {
        margin-bottom:0;

        li.product-line {
          display:flex;
          align-items:center;
          border-bottom:1px solid #f1f1f1;
          padding:5px 0;

          a {
            color:#000!important;
            font-size:13px;
          }

          &:last-child {
            border-bottom:none;
            padding:5px 0 0 0;
          }

          .product-line-image {
            margin-right:5px;
            img {
              width:50px;
            }
          }

          .product-line-name {
            font-size:15px;
            letter-spacing:0;
            line-height:1.1;
            margin-right:5px;
            max-width:170px;
            max-height:48px;
            overflow:hidden;
          }

          .product-line-qty-price {
            font-size:12px;
            letter-spacing:0;
            line-height:1;
            text-align:right;
            margin-left: auto;
            margin-right:10px;

            .qty-price-total {
              font-weight:700;
            }
          }
        }
      }
    }

    .cart-bottom {
      background:#eff1f7;
      padding:15px 10px;

      .cart-summary-line {
        font-size:14px;
        font-weight:400;
        line-height:1.2;

        .label {
          text-transform:uppercase;
          font-size:12px;
        }

        .value {
          letter-spacing:0.5px;
        }
      }

      a.btn {
        background:$brand-primary;
        box-shadow:none;
        color:#fff!important;
        text-transform:none;
        font-weight:400;
        margin-top:10px;
        margin-bottom:0;
        width:100%;
        border-radius:3px;
      }
    }

    .simplebar-scrollbar {
      right:0;
      width:4px;
    }

    .no-products {
      letter-spacing:normal;
    }
  }
}

.topextracontent {
  -webkit-box-shadow: 0px 2px 58px -21px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 58px -21px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 58px -21px rgba(0, 0, 0, 0.75);
  position:relative;
  z-index:1;
  margin-top:-70px;

  .advantages-wrapper {
    display:flex;
    align-items:center;
    justify-content:center;
    background:#fff;
    border-radius:5px;

    .advantage {
      color:$brand-secondary;
      border-right:1px solid #cac8c8;
      height:100px;
      font-size:16px;
      font-weight:600;
      line-height:1.2;
      display:flex;
      align-items:center;
      justify-content:center;
      padding:0 20px;
      transition:.2s;
      &:first-child {
        padding-left:0;
      }
      &:last-child {
        border-right:none;
      }

      i {
        font-size:40px;
        margin-right:20px;
      }

      &:hover {
        border-bottom:3px solid $brand-primary;
        i {
          color:$brand-primary;
          transition:.2s;
        }
      }
    }
  }
}

.newsletter-manager-wrapper {
  padding:4px;
  background: #abbdd8; /* Old browsers */
  background: -moz-linear-gradient(left, #abbdd8 0%, #88bad9 50%, #a4b4ce 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #abbdd8 0%,#88bad9 50%,#a4b4ce 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #abbdd8 0%,#88bad9 50%,#a4b4ce 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#abbdd8', endColorstr='#a4b4ce',GradientType=1 ); /* IE6-9 */
  margin-bottom:50px;

  #block-newsletter-label {
    font-size:20px;
    font-weight:600;
    color:$brand-primary;
    letter-spacing:0.5px;
  }

  .newsletter-send {
    box-shadow:none;
    text-transform:none;
    letter-spacing:1px;
    border-radius:5px;
    font-size:18px;
    padding:8px 30px;
    max-height:39px;
  }


  //.form-group {
  //  margin-bottom:0;
  //}

  .wrapped-error {
    background:red;
    padding:5px 10px;
    border-radius:5px;
    color:white;
    margin-bottom:10px;
    display:inline-block;
  }

  .wrapped-success {
    background:green;
    padding:5px 10px;
    border-radius:5px;
    color:white;
    margin-bottom:10px;
  }
}

.similar-products, .product-accessories {
  .tabs-title {
    margin-bottom: 15px;
    .subtitle-slider {
      font-size:12px;
      font-weight:600;
      color:#7a7c81;
      margin:5px 0;
    }
  }
  .slick-next, .slick-prev {
    top: -15px!important;
  }
}

.productsliders_block, .viewed-products, .similar-products, .product-accessories {
  .slider-group {
    margin-top:50px;
  }
  .tabs-title {
    .slider-title {
      font-size:18px;
      font-weight:700;
      color:$brand-secondary;
      text-transform:uppercase;
      border-bottom:1px solid #d7d7d7;
      margin-bottom:0;
      padding-bottom:4px;
      line-height: 1.1;
    }
    .subtitle-slider {
      font-size:12px;
      font-weight:600;
      color:#7a7c81;
    }
  }

  .slick-next, .slick-prev {
    width:24px;
    height:24px;
    top:0;
    border:1px solid #dcdcdc;
    border-radius:3px;
  }
  .slick-next {
    right:0;

    &:before {
      content:"\f0da";
      font-family:'Font Awesome 5 Free';
      height:100%;
      display:block;
      color:$brand-secondary;
      transition:.2s;
      margin-top:2px;
      margin-left:3px;
    }
    &:hover {
      &:before {
        color:$brand-primary;
        transition:.2s;
      }
    }
  }
  .slick-prev {
    right:30px;
    left:auto;

    &:before {
      content:"\f0d9";
      font-family:'Font Awesome 5 Free';
      height:100%;
      display:block;
      color:$brand-secondary;
      transition:.2s;
      margin-top:2px;
    }
    &:hover {
      &:before {
        color:$brand-primary;
        transition:.2s;
      }
    }
  }

  .thumbnail-container {
    width:200px;
    height:380px;
    margin-right:27px;
    margin-bottom:0;
    transition: .2s;

    .product-description {
      width:198px;
    }

    .highlighted-informations {
      width:200px;
      height:auto;
      padding-top:0;
      z-index:2;
      background:transparent;
      position:relative;
      bottom:-77px;
      pointer-events:none;

      .hover-buttons {
        margin-bottom:116px;
        pointer-events:all;
        button {
          width:40px;
          height:40px;
          background:#3f3f40;
          border-radius:3px;
          border:none;
          color:#fff;
          cursor:pointer;
        }
      }
      .vezi-detalii {
        background:#5ccbf4;
        padding:10px 0;
        cursor:pointer;
        pointer-events:all;

        a {
          color:#fff;
          font-size:16px;
        }
      }
    }
    //TODO ASTEA NU MERG
    @media (min-width: 992px) {
      &:hover {
        .product-thumbnail, .product-description {
          position: relative;
          margin-top: -40px;
          transition: .2s;
        }

        .product-thumbnail {
          margin-bottom: 40px;
        }

        .highlighted-informations {
          bottom: 0 !important;
          text-align: left;
          padding: 0 5px;
          span {
            color: $brand-secondary;
            font-size: 12px;
            line-height: 0;
          }
        }
      }
    }
  }
  .slick-slider {
    margin-bottom:50px!important;
  }
}

.similar-products {
  .slick-slider {
    margin-bottom:0!important;
  }
}

.viewed-products {
  article.product-miniature {
    padding-bottom:0;
  }
}


#left-column {
  padding-left:0;
}

body#contact {
  #content-wrapper {
    padding:0;
  }
}

body#index {
  .menu {
    .breadcrumb {
     &[data-depth="1"] {
       display:none;
     }
    }
  }
}

.breadcrumb-wrapper {
  background:#eff1f7;
  padding:13px 0;
  margin-top:-37px;
  margin-bottom:25px;
  .breadcrumb {
    padding: 0;
    margin:0 0 9px 0;
    &[data-depth="1"] {
      //display: none;
    }
    ol {
      margin-bottom:0;
    }
    li {
      display: inline-flex;
      align-items:center;
      &::after {
        content: '\f105';
        font-family: 'Font Awesome 5 Free';
        font-weight:900;
        color: $gray;
        font-size:12px;
        margin:0 3px 0 7px;
      }
      &:last-child {
        margin: 0;
        a {
          color: #8e8e8e!important;
          font-weight:600;
        }
        &::after {
          content: "";
        }
      }
      a {
        font-size:13px;
        font-weight:400;
        color: #878686!important;
        &:hover {
          color:#000!important;
        }
      }
    }
  }

  .category-title {
    display:flex;
    align-items:baseline;
    h2 {
      font-size:22px;
      font-weight:400;
      margin:0;
      margin-right:15px;
    }
    span {
      font-size:15px;
    }
  }
}

body#product {
  .breadcrumb-wrapper {
    .breadcrumb {
      margin:0;
    }
  }
}

//advancedlayerednavigation

#advanced_layered_navigation_filter {
  margin:13px 0 20px 0;
  //h2.title_block {
  //  font-size:16px;
  //  font-weight:400;
  //  text-transform:uppercase;
  //  color:#67cef5;
  //  letter-spacing:0;
  //  margin-top:24px;
  //  //margin-left:16px;
  //  background:#fff;
  //  padding:13px 16px;
  //  margin-bottom:13px;
  //}

  .af_filter {
    margin-bottom:35px;
    .af_subtitle_heading {
      h5.af_subtitle {
        font-size:14px;
        color:$brand-secondary;
        letter-spacing:0;
        font-weight:900;
        border-bottom:1px solid #ccc;
        padding:0 0 10px 0;
        margin-bottom:10px;
        position:relative;

        &:before {
          top:6px;
        }
        //&:after {
        //  content:"";
        //  position:absolute;
        //  width:75px;
        //  height:2px;
        //  bottom:-1px;
        //  left:0;
        //  border-bottom:2px solid $brand-primary;
        //}
      }
    }

    .af_filter_content {
      li {
        padding:3px 0;
        label {
          display:flex;
          align-items:center;
          margin-bottom:0;
          .custom-checkbox {
            margin-top:-4px;
            .checkbox {
              margin:0!important;
            }
            input[type="checkbox"] {
              opacity: 0;
              cursor: pointer;
              position: absolute;
              + span {
                margin-right: 5px;
                display: inline-block;
                width: 15px;
                height: 15px;
                cursor: pointer;
                border: 1px #d1d1d1 solid;
                border-radius:2px;
                background:#e9e9e9;
                .checkbox-checked {
                  display: none;
                  margin:0;
                  font-size:15px;
                  color: #fff;
                }
              }
              &:checked + span {
                background:$brand-primary;
                border:none;
                .checkbox-checked {
                  display: block;
                }
              }
              &:focus + span {
                border-color: $gray;
              }
            }
          }
          span.name {
            margin-top:0;
            color: #605f5f;
            display: inline-block;
            font-size:14px;
            padding-left:2px;
          }
          .count {
            margin-left:5px;
          }
        }
      }
    }
  }


  .facet-label {
    display:flex;
    align-items: center;
    margin-bottom:5px;
    font-size:16px;

    a {
      margin-top:0;
      color: #605f5f;
      display: inline-block;
      font-size:16px;
      margin-left:5px;
    }
    .custom-radio {
      background:#f8f8f8;
    }
  }

  .selectedFilters {
    .clearAll {
      font-size:14px;
      background:#eee;
      border-top-left-radius:3px;
      border-top-right-radius:3px;
      margin-bottom:5px;

      //display:flex;
      //align-items:center;
      //justify-content:center;

      a {
        display:flex;
        align-items:center;
        justify-content:flex-start;
        position:static!important;
        padding:1px;
        width:100%;

        &:before {
          margin-right:10px;
        }
      }

    }
    .cf {
      border:1px solid #eee;
      margin-top:-1px;
      background:none;
      border-radius:3px;
      margin-bottom:5px;
      font-size:14px;

      &:last-child {
        margin-bottom:20px;
      }
    }
  }
}

//endandvancedlayerednavigation

.comments_note {
  width:auto!important;
  margin-bottom:0!important;
  cursor:pointer;
  .fa-star {
    color:#e2e2e2;
    font-size:16px;
  }

  .star-active {
    color:#ffd600;
  }
}

.comment_author_infos {
  .fa-star {
    color:#e2e2e2;
    font-size:15px;
  }

  .star-active {
    color:#ffd600;
  }
}

body#search, body#manufacturer {
  #left-column {
    display:none;
  }
  #products {
    .product-description {
      bottom:-4px;
    }
  }
}

#search_filters_brands {
  .slick-next, .slick-prev {
    top:-19px;
  }
}

body#index {
  #search_filters_brands {
    .slick-next, .slick-prev {
      top:-9px;
    }
  }
}

.cart-summary-products {
  .product-price {
    font-size:16px;
  }
}

#registration-form {
  label.radio-inline {
    margin-right:10px;
    display:inline-flex;
    align-items:center;
    .custom-radio {
      margin-right:5px;
    }
  }

}
