@include media-breakpoint-up(xl) {
  .thumbnail-container {
    //height:380px;
    height:402px;
    max-height:none!important;
  }
}

@include media-breakpoint-down(lg) {
  .container {
    width:100%;
    margin-left:auto;
    margin-right:auto;
    padding-left:15px;
    padding-right:15px;
  }
  .thumbnail-container {
    height:100%!important;
  }

  .block_newsletter {
    .newsletter-send {
      padding:8px 19px;
    }
  }

  body#search, body#manufacturer {
    #products {
      .product-description {
        bottom:0;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .col-lg-20 {
    width:20%!important;
    padding-left:15px;
    padding-right:15px;
  }
  .col-lg-200 {
    width:20%!important;
    padding-right:32px!important;
  }

  .col-lg-80 {
    width:80%!important;
  }

  body#search, body#manufacturer {
    .col-lg-80 {
      width:100%!important;
    }
  }

  //body#index {
  //  #search_filters_brands {
  //    margin-top:0;
  //  }
  //}
}


@include media-breakpoint-down(md) {
  #account, #shoppingcart {
    width:38px;
    background:none;
  }
  #shoppingcart {
    margin-left:0;
  }
  #account {
    a.icon {
      margin-top:2px;
    }
  }

  .productsliders_block {
    .slider-group {
      margin-top:0;
    }
  }

  .viewed-products .slick-slider {
    margin-bottom:0!important;
  }

  //add to cart modal margin
  .modal-dialog {
    margin:100px auto;
  }

  .block_newsletter {
    .newsletter-send {
      padding:8px;
      font-weight:400;
    }
  }

  body#index {
    .newsletter-manager-wrapper {
      margin-top:-30px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .position-absolute-md {
    position:absolute;
  }

  #_mobile_search_trigger {
    font-size:19px;
    margin-top:3px;
    margin-right:10px;
    cursor:pointer;
    color:#fff;
    padding:6px;
    border-radius:30px;
    width:33px;
    height:33px;
  }

  #sec_search_widget {
    width:100%;
    margin-left:0;
    input[type=search] {
      width:100%!important;
    }
    .spinner {
      display:none;
      position:absolute;
      top:5px;
      right:50px;
    }
    button {
      right:5px!important;
    }
  }

  .header-top {
    .modal-open .modal-search {
      overflow-x:hidden;
      overflow-y:auto;
    }

    .modal.in .modal-dialog-search {
      transform:translate(0,0);
    }

    .modal-search {
      display:none;
      position:fixed;
      top:0;
      right:0;
      bottom:0;
      left:0;
      z-index:1050;
      outline:0;
      height:1000%;

      .modal-dialog-search {
        position:relative;
        width:100%;
        max-width:100%;
        margin:0;

        .modal-content-search {
          position:relative;
          background-color:#fff;
          background-clip:padding-box;
          outline:0;
          border:none;
          border-radius:0;

          .modal-body-search {
            position:relative;
            padding:15px;
            form {
              position:relative;

              input[type=text] {
                width:100%;
                border:1px solid #ccc;
                padding:10px;
                &:focus {
                  outline:none;
                }
              }

              button.close {
                position:absolute;
                top:0;
                right:50px;
                cursor:pointer;
                height:100%;
              }
            }
          }
        }
      }
    }
  }

  #js-product-list-top {
    display: block;
    border-bottom:none;

    .total-products {
      justify-content:center;
      margin-bottom:15px;
      h1 {
        text-transform:uppercase;
        margin-right:0;
        &:after {
          display:none;
        }
      }
      span {
        display:none;
      }
    }

    .btn-orderby, .trigger-filter{
      padding: 5px 10px;
      font-size:12px;
      font-weight:400;
      width:100%;
      span {
        font-size:10px;
        letter-spacing:0.5px;
        text-transform:lowercase
      }
    }
  }

  .breadcrumb-wrapper {
    margin-bottom:0;
  }

  .sort-by-row {
    padding:10px 0;
  }

  .fixed-buttons {
    position:fixed;
    //width:100%;
    z-index:1052;
    top:71px;
    left:0;
    right:0;
    background:#e9ebee;
    margin:0;
    transition-timing-function:ease-in-out;
    -webkit-transition-timing-function:ease-in-out;
    -o-transition-timing-function:ease-in-out;
    -webkit-transition-duration:.2s;
    -o-transition-duration:.2s;
    transition-duration:.2s;
    box-shadow:0 2px 10px 0 rgba(0,0,0,.2);
  }


  #advanced_layered_navigation_filter {
    margin-bottom:0;

    #af_form {
      display:none;
    }

    h5.af_subtitle {
      font-size:16px;
      text-transform:uppercase;
      letter-spacing:0;
      font-weight:400;
      color:#555454;
      margin-bottom:10px;
      border-bottom:2px solid #cac8c8;
      padding-bottom:10px;
      position:relative;

      &:before {
        visibility:visible!important;
      }
    }

    .selectedFilters {
      //overflow-x:scroll;
      overflow-y:hidden;
      white-space:nowrap;
      //height:50px;
      padding-top:1px;
      margin-bottom:15px;

      .cf {
        display:inline-block;
        margin-right:10px;
        padding:5px 30px 5px 5px;
      }

      .clearAll {
        display: inline-block;
        margin-right:10px;
        padding-right:33px;
      }
      .hidden {
        display:none;
      }
    }
  }

  .product-add-to-cart {
    padding:20px 0!important;
    .product-quantity {
      justify-content:flex-start;
    }
  }

  body#search, body#manufacturer {
    .products-selection {
      .sort-by-row {
        justify-content:center;
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .af_pl_wrapper {
    #js-product-list {
      padding:0 15px;
    }
  }
}

@media screen and (max-width: 1225px) {
  .block-social {
    display:none;
  }
}

@media screen and (max-width: 413px) {
  .product-quantity .add {
    padding-left:0!important;
    margin-left:0;
  }
}