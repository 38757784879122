.header-menu {
  li.modpr_type {
    float:left;
    list-style:none;
    padding:10px 17px;
    a {
      font-size:16px!important;
      letter-spacing:1.5px;
      font-weight:700;
      text-transform:uppercase;
      transition:.2s;

      &:hover {
        color:$brand-primary;
        transition:.2s;
      }
    }
  }
}

.range {
  border:1px solid #eff1f7;
  border-radius:3px;
  margin-bottom:15px;
  box-shadow:0 2px 12px -4px rgba(0,0,0,0.1);
  transition:.2s;

  &:hover {
    box-shadow:5px 6px 12px -4px rgba(0, 0, 0, 0.3);
    transition:.2s;
  }

  .range-name {
    padding: 10px;
    a {
      color:$brand-secondary;
      font-size:18px;
    }
  }

  .range-image {
    //min-height:200px;
    max-height:200px;
    overflow:hidden;
  }
}

.ranges-wrapper {
  background:#24242e;
  padding:40px 0;
  p {
   color:#eff1f7;
    &.ranges-title {
      font-size:25px;
      margin-bottom:10px;
    }
    &.ranges-subtitle {
      font-size:16px;
    }
  }
  #ranges-slider {
    margin:0 -10px!important;
    .range {
      border:1px solid #eff1f7;
      margin:0 10px;

      .range-name {
        a {
          color:#eff1f7;
          font-size:18px;
        }
      }
    }
  }
}

.p-range {
 p {
   color:$brand-secondary;
   a {
     font-size:16px;
     font-weight:600;
     img {
       margin-bottom:10px;
     }
   }
 }
  a.btn-outline-primary {
    width:100%;
    margin-top:10px;
  }
}



