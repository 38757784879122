#sec_desktop_top_menu {
  z-index:5;
  width:100%;
  height:40px;
  margin-bottom:0;
  padding:0;

  .container {
    padding:0;
  }

  //TOATE ul-urile
  ul.top-menu {
    margin-bottom:0;

    //ul level 1
    &[data-depth="1"] {
      margin: 0;
      padding:0;
    }

    li {
      float:left;
    }

    li.category {
      position:static;

      /*Produse*/
      &:first-child{
        a[data-depth="0"] {
          font-size:16px;
          font-weight:700;
          letter-spacing:1.5px;
          text-transform:uppercase;
          padding:10px 17px;
          color: #fff;
          background: $brand-primary;
          width: 265px;
          height:40px;
          max-height:40px;
          background-image: url(../img/menu-icon.svg);
          background-repeat:no-repeat;
          background-position:15px 14px;
          padding-left:47px;
          transition:.2s;
        }
      }

      /*link-uri categorii*/
      a[data-depth="1"], a[data-depth="2"] {
        padding:0;
      }
    }

    /*Produse not homepage*/
    &.not-homepage li.category:first-child {
      a[data-depth="0"] {
        background: transparent;
        width:130px;
        background-image: url(../img/menu-icon.svg);
        background-repeat:no-repeat;
        background-position:0 13px;
        padding-left:32px;

        &:hover {
          color:$brand-primary;
          transition:.2s;
        }
      }
    }

    li.link {
      float:left;
      list-style:none;
      padding:10px 17px;
      a {
        font-size:16px!important;
        letter-spacing:1.5px;
        font-weight:700!important;
        text-transform:uppercase;
        transition:.2s;
        padding:0;

        &:hover {
          color:$brand-primary!important;
          transition:.2s;
        }
      }
    }

    .collapse {
      display: inherit;
    }

    &[data-depth="0"] {
      /*Submeniu 1*/
      .sub-menu {
        display: none;
        top:40px;
        left:0;
        margin-left:0!important;
        //min-width:212px;
        //width:212px;
        //max-width:212px;
        width: 265px;
        background:#fff;
        padding:0;
        box-shadow:1px 1px 3px 1px rgba(0, 0, 0, 0.22);
        border-bottom-left-radius:3px;
        border-bottom-right-radius:3px;

        ul[data-depth="1"] li {
          float:none;
          position: unset;
          padding:0 7px;
          margin:0;
          margin-bottom:10px;

          a {
            text-transform:uppercase;
            font-size:14px;
            font-weight:400;
            color:#333;
            padding-left:14px;
            transition:.3s;

            img {
              margin-right:1px;
              margin-top:-7px;
            }
          }

          &:hover {
            a {
              color:$brand-primary;
              transition:.3s;
            }
          }

          &:last-child {
            a {
              border-bottom:none!important;
            }
          }
          a[data-depth="1"] {
            font-size:14px;
            text-transform:uppercase;
            color:$brand-secondary;
            padding:10px 10px;
            letter-spacing:1.2px;
            font-weight:600;
            position:relative;
          }

          .sub-menu2 {
            display: none;
            left:190px;
            top:-40px;
            padding-top:14px;
            height:calc(100% + 40px);
            overflow:hidden;
            border:none;
            background:#fff;
            width:285px;
            border-bottom-left-radius:5px;
            border-bottom-right-radius:5px;
            z-index:2;

            ul[data-depth="3"] {
              display:flex;
              flex-direction:column;
              flex-wrap:wrap;
              height:100%;
              li {
                float:none;
                padding-top:10px;
                position:unset;
                border:none;
                margin-bottom:10px;
              }
            }

            a[data-depth="2"] {
              font-size:14px;
            }

            li.category:first-child a[data-depth="2"]{
              //font-weight:bold;
            }

            ul[data-depth="3"] {
              //margin-top:-10px;
              li {
                border:none;
                padding:0;
              }
            }

            a[data-depth="3"] {
              font-size:14px;
              padding: 10px 0 5px 14px;
            }

            a[data-depth="4"] {
              text-transform:none;
              padding:0 0 0 25px;
            }
          }

          .sub-menu2-sticky {
            background:#fff!important;
            top:0;
            height:100%;
            ul[data-depth="2"] li {
              margin-bottom:2px;
              &:nth-last-child(2) {
                padding-bottom:4px;
              }
            }
          }
        }

        ul[data-depth="2"] {
          padding:0 20px 0 0;
          //margin-left:15px;
          margin-left:10px;
        }

        ul[data-depth="2"] li {
          margin-bottom:7px;
          padding:0;
          &:hover {
            background:none;
          }

          //&:nth-last-child(2) {
          //  border-bottom:1px solid #e0e0e0;
          //  padding-bottom:9px;
          //  margin-bottom:0;
          //}

          a[data-depth="2"] {
            font-size:14px;
            font-weight:400;
            color:#555454;
            padding:0;
            letter-spacing:0.2px;
            text-transform:none;
            white-space:normal;

            &:before {
              content: "";
              width:5px;
              height:6px;
              background: url(../img/sm-right-arrow.svg);
              display:inline-block;
              position:relative;
              top:-1px;
            }

            &:hover {
              background:none;
              color:$brand-primary;
              font-weight:700;

              &:before {
                content: "";
                width:5px;
                height:6px;
                background: url(../img/sm-right-arrow-blue.svg);
                display:inline-block;
                position:relative;
                top:-1px;
              }
            }
          }
        }
      }
    }


    .sub-menu {
      &.collapse {
        display: none;
      }
      box-shadow: 2px 1px 11px 2px rgba(0, 0, 0, 0.1);
      border: none;
      margin-left: 0.9375rem;
      //width: calc(100% - 30px);
      //min-width: calc(100% - 30px);
      z-index: 18;
      display: none;
      ul[data-depth="1"] > li {
        float: left;
        margin: 0 1.25rem;
      }
      a:hover {
        color: $brand-primary;
      }
    }
    .popover {
      max-width: inherit;
      border-radius: 0;
    }
  }
}

.popover.bs-tether-element-attached-top {
  margin-top: 0;
}


@media (max-width: 992px) {
  //body#index {
  //  #sec_desktop_top_menu {
  //    //PRODUSE
  //    .top-menu li.category:first-child a[data-depth="0"] {
  //      width:100%!important;
  //    }
  //    ul[data-depth="0"] li {
  //      .sub-menu {
  //        min-width:100%!important;
  //        width:100%!important;
  //        max-width:100%!important;
  //        background:none;
  //        box-shadow:none;
  //
  //        ul[data-depth="1"] li {
  //          .sub-menu2 {
  //            left:255px!important;
  //          }
  //        }
  //      }
  //    }
  //  }
  //}

  .trigger-menu {
    z-index:2;
  }

  #myHeader {
    position: fixed;
    top: 0;
    width: 100%;
    transition:.2s;
    transform:translateY(0);
    padding:10px 0!important;
    border-bottom:2px solid $brand-primary;
    height:71px;
    display:flex;
    align-items:center;
    z-index:16;
    background:#20222e;
  }

  body#index {
    #wrapper {
      padding-top:33px!important;
    }
  }

  #wrapper {
    padding-top:95px!important;
  }

  #sec_desktop_top_menu {
    display:none;
    padding-top:59px;

    ul.top-menu {
      //ul level 0
      &[data-depth="0"] {
        position: fixed;
        width:100%;
        height:100%;
        background:#eff1f7;
        margin-left:-15px;
        //overflow-y:scroll;
        padding-bottom:70px;

        li {
          width:100%;

          a[data-depth="0"] {
            border-bottom:none;
          }
        }
      }

      //ul level 1
      &[data-depth="1"] {
        margin: 0;
        padding:0;
      }

      li {
        float:left;
      }

      li.category {
        position:static;

        /*Produse*/
        &:first-child{
          a[data-depth="0"] {
            font-size:20px;
            font-weight:700;
            letter-spacing:1px;
            text-transform:none;
            color: $brand-primary;
            background: none;
            padding-left:15px;
            transition:.2s;
          }
        }

        /*link-uri categorii*/
        a[data-depth="1"], a[data-depth="2"] {
          padding:0;
        }
      }

      /*Produse not homepage*/
      &.not-homepage li.category:first-child {
        a[data-depth="0"] {
          padding-left:15px;
          background: none;
        }
      }

      li.link {
        float:left;
        list-style:none;
        padding:10px 17px;
        a {
          color:$brand-primary;
          font-size:20px!important;
          letter-spacing:1px;
          font-weight:700!important;
          text-transform:none;
          transition:.2s;
          padding:0;
        }
      }

      .collapse-icons[aria-expanded="true"] {
        .add {
          display: none;
        }
        .remove {
          display: block;
        }
      }
      .collapse-icons .remove {
        display: none;
      }
      .navbar-toggler {
        display: inline-block;
        padding: 0;
      }
      .collapse {
        display: none;
        &.in {
          display: block;
        }
      }

      &[data-depth="0"] {
        .sub-menu {
          position:relative;
          top:0;
          display:block!important;
          background:none;
          width:100%;
          box-shadow: none;
          z-index: inherit;
          overflow: hidden;
          margin-left: 0;
          min-width: 100%;
          &.collapse {
            display: none;
            &.in {
              display: block;
            }
          }

          ul[data-depth="1"] {
            margin-bottom:15px;
            li {
              float: none;
              margin: 0;
              padding-left:15px;

              a[data-depth="1"] {
                font-size:16px;
                letter-spacing:1px;
                text-transform:none;
                color:$brand-secondary;
                padding:10px 4px!important;
              }
              a {
                text-transform: none;
              }
            }
          }
          ul {
            padding: 0;
          }
          li > a {
            padding: 0.625rem;
            font-weight: bold;
          }
          ul[data-depth="2"] {
            margin-bottom:10px;
            li {
              padding:5px 0;
            }
          }
          ul[data-depth="2"] li a {
            padding-left: 1.25rem;
          }
          ul[data-depth="3"] li a {
            padding-left: 2.5rem;
          }
          ul[data-depth="4"] li a {
            padding-left: 3.75rem;
          }
        }
        .sub-menu2 {
          position:static;
          border:none;
          //&.collapse {
          //  display: none;
          //  &.in {
          //    display: block;
          //  }
          //}
        }
      }

    }

    //ul[data-depth="0"] {
    //  li {
    //    .sub-menu {
    //      ul[data-depth="1"] {
    //        li {
    //          .sub-menu2 {
    //            border-radius:5px;
    //            width:100%;
    //            min-width:100%;
    //          }
    //        }
    //      }
    //      ul[data-depth="2"] {
    //        margin-left:0;
    //        padding:0;
    //        li:nth-last-child(2) {
    //          border-bottom:none;
    //        }
    //      }
    //    }
    //  }
    //}
  }
}



//sticky menu
.header-top.sticky {
  background: #20222e;
  z-index: 1051;
}

.sticky-minimized {
  position:fixed;
  top:-100px;
  width: 100%;
  transition:.2s;
  transform:translateY(-100%);
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  transition:.2s;
  transform:translateY(0);
  padding:10px 0!important;
  border-bottom:2px solid $brand-primary;

  @media (min-width: 992px) {
    #logo {
      margin-left: -50px;
    }
  }
}


.sticky-minimized + #wrapper {
  padding-top: 200px;
}

.sticky + #wrapper {
  padding-top: 102px;
}

.shown-menu {
  display:block!important;
  position:fixed!important;
  top:62px!important;
}


//@media (min-width: 992px) {
//  body#index {
//    #sec_desktop_top_menu {
//      //PRODUSE
//      .top-menu li.category:first-child a[data-depth="0"] {
//        width:255px!important;
//      }
//      ul[data-depth="0"] li {
//        .sub-menu {
//          min-width:255px!important;
//          width:255px!important;
//          max-width:255px!important;
//
//          ul[data-depth="1"] li {
//            .sub-menu2 {
//              background:rgba(255, 255, 255, 0.8);
//              left:255px!important;
//            }
//          }
//        }
//      }
//    }
//  }
//}
