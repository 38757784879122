#products {
  color: $gray;
  .products-select {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
  .up {
    margin-bottom: 1rem;
    .btn-secondary {
      color: $gray;
      text-transform: inherit;
      .material-icons {
        margin-right: 0;
      }
    }
  }
}
.block-category {
  position:relative;
  min-height: 13.75rem;
  margin-bottom: 70px;
  h2 {
    font-size:45px;
    color:$brand-secondary;
  }
  #category-description {
    height:180px;
    overflow:hidden;
    p,
    strong,
    span {
      font-size:16px!important;
      font-family:'PT Sans';
      font-weight: 400;
      color: $brand-secondary;
    }
  }
  .read-more {
    background:url(../img/category-overlay.png);
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    span {
      color:$brand-primary;
      font-size:14px;
      font-weight:600;
      position:absolute;
      bottom:-1.563rem;
      left:0;
      cursor:pointer;
    }
  }
  .category-cover {
    position: absolute;
    right: 0.75rem;
    bottom: 0;
    img {
      width: 141px;
      height: 180px;
    }
  }
}
.products-selection {
  border-bottom: 1px solid #d7d7d7;
  letter-spacing:0.2px;
  display:flex;
  align-items:center;
  .sort-by-row {
    display: flex;
    align-items: center;
    justify-content:flex-end;
  }
  .sort-by {
    white-space: normal;
    word-break: break-word;
    text-align: right;
    font-size:14px;
    color:#5d5d5d;
    margin-right:8px;
  }
  .total-products {
    //padding-top: 0.625rem;
    display:flex;
    align-items:center;
    padding-left:0;
    h1 {
      font-size:18px;
      color: $brand-primary;
      font-weight:800;
      margin-bottom:0;
      margin-right:10px;
      letter-spacing:0.2px;
      &:after {
        content:"";
        width:calc(100% + 20px);
        border-bottom:4px solid $brand-primary;
        display:block;
        margin-top:10px;
      }
    }
    span {
      margin-top:-14px;
      font-size:14px;
      color:#5d5d5d;
    }
  }

}

#orderbymodal {
  padding:0 15px;
  .select-list {
    display: block;
    color:#585757;
    padding: 5px;
    font-size:14px;
    &:hover {
      background: $brand-primary;
      color: white;
      text-decoration: none;
    }
  }
}

.nb-products {
  display: inline-block;
  color: #5d5d5d;
  background: white;
  padding: 2px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius:2px;
  font-size:14px;
  margin-left:5px;
  &:after {
    content:'\f0dd';
    font-family:'Font Awesome 5 Free';
    font-weight:900;
    display:inline-block;
    position:absolute;
    right:10px;
    top:1px;
  }
}

.products-sort-order {
  color: $gray;
  .select-title {
    display: inline-block;
    width: 100%;
    color: #5d5d5d;
    background: white;
    padding: 2px 8px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius:2px;
    font-size:14px;
    text-transform:lowercase;
    margin-right:23px;
    letter-spacing:0.5px;
    &:after {
      content:'\f0dd';
      font-family:'Font Awesome 5 Free';
      font-weight:900;
      display:inline-block;
      position:absolute;
      right:10px;
      top:1px;
    }
  }
  .select-list {
    display: block;
    color: $gray-darker;
    padding: $small-space $medium-space;
    &:hover {
      background: $brand-primary;
      color: white;
      text-decoration: none;
    }
  }
  .dropdown-menu {
    left: auto;
    right:0;
    width: 16.88rem;
    background: $gray-lighter;
    border: none;
    //box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1);
    margin: 0;
    border-radius:5px;

  }
}
.facet-dropdown {
  color: $gray;
  padding-left: 0;
  padding-right: 0;
  background: $gray-light;
  border: 3px solid transparent;
  box-sizing: border-box;
  box-shadow: 1px 1px 1px 1px $gray-light;
  &.open {
    border: 0;
    > .select-title {
      border: 3px solid $brand-primary;
      background: $gray-lighter;
    }
  }
  .select-title {
    display: inline-flex;
    align-items: center;
    margin-left: 0;
    width: 100%;
    color: $gray-darker;
    background: $gray-light;
    padding: $small-space $small-space $small-space $medium-space;
    cursor: pointer;
    > i {
      margin-left: auto;
    }
  }
  .select-list {
    display: block;
    color: $gray-darker;
    background: $gray-lighter;
    padding: $small-space $medium-space;
    &:hover {
      background: $brand-primary;
      color: white;
      text-decoration: none;
    }
  }
  .dropdown-menu {
    padding: 0;
    margin-top: 3px;
    left: auto;
    width: 100%;
    background: $gray-lighter;
    border: none;
    box-shadow: 1px 1px 1px 1px $gray-light;
  }
}


#search_filters,
#search_filters_brands,
#search_filters_suppliers {
  margin-top: 0;
  .facet {
    .collapse {
      display: block;
    }
    padding-top: $small-space;
    .facet-title {
      color: $gray;
    }
    .facet-label {
      margin-bottom: 0;
      a {
        margin-top: 0.4375rem;
        color: $gray-darker;
        display: inline-block;
        font-size:$font-size-lg;
      }
    }
  }

  h3 {
    font-size:18px;
    color:$brand-primary;
    border-bottom:1px solid #d7d7d7;
    margin-bottom:40px;
    &:after {
      content:"";
      display:block;
      width:135px;
      height:4px;
      background:$brand-primary;
      margin-top:10px;
    }
  }
  .slick-next, .slick-prev {
    width:24px;
    height:24px;
    top:-9px;
    border:1px solid #dcdcdc;
    border-radius:50%;
  }
  .slick-next {
    right:0;

    &:before {
      content:"";
      background-image:url(../img/right-arrow.png);
      background-position:center;
      background-repeat:no-repeat;
      width:24px;
      height:100%;
      display:block;
    }

    &:hover {
      border:1px solid $brand-primary;
      transition:.1s;
      &:before {
        background-image:url(../img/right-arrow-hover.png);
      }
    }

  }
  .slick-prev {
    right:30px;
    left:auto;

    &:before {
      content:"";
      background-image:url(../img/left-arrow.png);
      background-position:center;
      background-repeat:no-repeat;
      width:19px;
      height:100%;
      display:block;
    }

    &:hover {
      border:1px solid $brand-primary;
      transition:.1s;
      &:before {
        background-image:url(../img/left-arrow-hover.png);
      }
    }
  }
}


#search_filters_brands,
#search_filters_suppliers {
  .facet {
    padding-top: 0;
    .facet-label {
      margin-bottom: 0.3125rem;
    }
  }
}

.pagination {
  width: 100%;
  display: flex;
  align-items:center;
  margin-top:0;
  > div:first-child{
  }
  .count-products {
    margin-top:7px;
    span {
      color:#3f3f40;
      font-size:14px;
      font-weight:800;
    }
  }
  .page-list {
    margin-bottom: 0;
    display:inline-flex;
    align-items:center;
    justify-content:flex-end;
    border:1px solid #ccc;
    border-radius:2px;

    .fas {
      font-size:10px;
      color:#5d5d5d;
    }

    li {
      width:28px;
      height:28px;
      display:flex;
      align-items: center;
      justify-content:center;
      background:#fff;
      color:#5d5d5d;

      &:hover {
        color:$brand-primary;
      }
    }
    li.current {
      background:#e2e2e2!important;
      border-bottom:2px solid $brand-primary;

    }
    li.previous, li.next {
      border:none;

      button {
        border:none;
        background:#fff;
        cursor:pointer;
        font-size:15px;
        color:#6b6b6a;
      }
    }
  }
  a {
    width:100%;
    color: #5d5d5d;
    font-size: 14px;
    &:not(.previous):not(.next) {
    }
    &:hover, &:focus {
      text-decoration:none;
      color:$brand-primary;
    }
    text-align:center;
  }
  .previous {
    float: left;
  }
  .next {
    float: right;
  }
  .disabled {
    color: $gray;
  }
  .current a {
    color: #5d5d5d;
    text-decoration: none;

  }

  .items_no {
    padding-left:0;
    font-size:14px;
  }
}
.active_filters {
  background: #dededd;
  padding: $small-space $large-space 0;
  margin-bottom: $medium-space;
  .active-filter-title {
    display: inline;
    margin-right: $small-space;
    font-weight: 600;
  }
  ul {
    display: inline;
  }
  .filter-block {
    @include box-shadow;
    color: $gray-darker;
    margin-right: $small-space;
    margin-bottom: $small-space;
    background: white;
    padding: 0.625rem;
    display: inline-block;
    font-size: $font-size-xs;
    .close {
      color: $gray-darker;
      font-size: $font-size-lg;
      opacity: 1;
      margin-top: 0.1875rem;
      margin-left: 0.3125rem;
    }
  }
}
.block-categories {
  background: white;
  padding: 13px 10px;
  margin-bottom: 16px;
  border:1px solid #ccc;
  border-radius:5px;
  letter-spacing:0.2px;

  .category-top-menu {
    margin-bottom:0;
    a.h6 {
      display: block;
      color:#7d7d7d;
      width:100%;
      border-bottom:1px solid #ccc;
      font-size:14px;
      font-weight:800;
      letter-spacing:0.2px;
      margin-bottom:10px;
      &:after {
        content:"";
        border-bottom:2px solid $brand-primary;
        width:75px;
        display: block;
        margin-top:11px;
        margin-bottom:-1px;
      }
    }
  }

  .category-sub-menu {
    margin-top: 6px;
    margin-bottom:-7px;
    .category-sub-link{
      font-size: $font-size-sm;
    }
    li{
      position: relative;
      padding-left:2px;
      &[data-depth="0"] {
        margin-bottom:7px;
      }
      &[data-depth="1"]{
        margin-bottom: 0;
      }
    }
    li[data-depth="0"] > a {
      //width: 100%;
      width: 86%;
      display: inline-block;
      letter-spacing:0;
    }
    li[data-depth="1"] > a {
      width: 100%;
      display: inline-block;
      padding-left:11px;
      font-size:12px;
      line-height:2.4;
      &:before {
        content:"";
        width:5px;
        height:6px;
        background: url(../img/sm-right-arrow.svg);
        display: inline-block;
        position: relative;
        top:-1px;
        margin-right:3px;
      }
      //margin: 0.3125rem 0 0;
      //padding-bottom: 0.1875rem;
    }
    li:not([data-depth="0"]):not([data-depth="1"]) {
      padding-left: 0.3125rem;
      &::before {
        content: "-";
        margin-right: 0.3125rem;
      }
    }
    .navbar-toggler {
      width:23px;
      height:23px;
    }
  }
  a {
    color: #000;
  }
  .collapse-icons {
    position: absolute;
    right: 0;
    top: -3px;
    padding: 0;
    cursor: pointer;
    &[aria-expanded="true"] {
      .add {
        display: none;
      }
      .remove {
        display: block;
      }
    }
    .add,
    .remove {
      &:hover {
        color: $brand-primary;
      }
    }
    .remove {
      display: none;
    }
  }
  .arrows {
    .arrow-right,
    .arrow-down {
      font-size: $font-size-sm;
      cursor: pointer;
      margin-left: 2px;
      &:hover {
        color: $brand-primary;
      }
    }
    .arrow-down {
      display: none;
    }
    &[aria-expanded="true"] {
      .arrow-right {
        display: none;
      }
      .arrow-down {
        display: inline-block;
      }
    }
  }
}
.facets-title {
  color: $gray-darker;
}
.products-selection {
  .filter-button {
    .btn-secondary {
      padding: 0.75rem 0.5rem 0.6875rem;
    }
  }
}

/*** Responsive part ***/
@include media-breakpoint-down(md) {
  .pagination {
    .page-list {
      li.current {
        width:70px;
        border-bottom:none;
      }
    }
  }
}
@include media-breakpoint-down(sm) {
  #category {
    #left-column {
      width: 100%;
      #search_filters_wrapper {
        margin-left: -30px;
        margin-right: -30px;
      }
      #search_filter_controls {
        text-align: center;
        button {
          margin: 0 0.5rem;
        }
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
      #search_filters {
        margin-bottom: 0;
        box-shadow: none;
        padding: 0;
        border-top: 1px solid $gray-lighter;
        .facet {
          .title {
            cursor: pointer;
            .collapse-icons .remove {
              display: none;
            }
          }
          .title[aria-expanded="true"] {
            .collapse-icons {
              .add {
                display: none;
              }
              .remove {
                display: block;
              }
            }
          }
          padding-top: 0;
          border-bottom: 1px solid $gray-lighter;
          .facet-title {
            color: $gray-darker;
            text-transform: uppercase;
          }
          .h6 {
            margin-bottom: 0;
            padding: 0.625rem;
            display: inline-block;
          }
          .navbar-toggler {
            display: inline-block;
            padding: 0.625rem 0.625rem 0 0;
          }
          .collapse {
            display: none;
            &.in {
              display: block;
            }
          }
          .facet-label {
            a {
              margin-top: 0;
            }
          }
          ul {
            margin-bottom: 0;
            li {
              border-top: 1px solid $gray-lighter;
              padding: 0.625rem;
            }
          }
        }
      }
    }
    #content-wrapper {
      width: 100%;
      clear:both;
    }
    #search_filter_toggler {
      width: 100%;
    }
  }
  .products-sort-order {
    .select-title {
      margin-left: 0;
    }
  }
  .products-selection {
    h1 {
      padding-top: 0;
      text-align: center;
      margin-bottom: 1rem;
    }
    .showing {
      padding-top: 1rem;
    }
  }
  #prices-drop #content-wrapper,
  #new-products #content-wrapper,
  #best-sales #content-wrapper {
    width: 100%;
  }

  .pagination {
    justify-content: center;
  }
}

@include media-breakpoint-down(xs) {
  //.products-selection {
  //  .filter-button {
  //    padding-left: 0;
  //  }
  //}
  #category {
    #left-column {
      #search_filters_wrapper {
        margin-left: -15px;
        margin-right: -15px;
      }
    }
  }
}
