#product {
  #content {
    position: relative;
    border:1px solid #ccc;
    border-radius:5px;
    overflow:hidden;
    margin-bottom:40px;
  }
}
.product-price {
  color: $brand-primary;
  display: inline-block;
  font-size:29px;
  font-weight:800;
  letter-spacing:0.3px;
}
#product-description-short {
  color: $gray-darker;
}
.product-information {
  font-size: $font-size-lg;
  color: $gray-darker;

  .manufacturer-logo {
    height: 35px;
  }

  .product-description img {
    max-width: 100%;
    height: auto;
  }
}
.input-color {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 1.25rem;
  width: 1.25rem;
}
.input-container {
  position: relative;
}
.input-radio {
  position: absolute;
  top: 0;
  cursor: pointer;
  opacity: 0;
  width: 100%;
  height: 100%;
}
.input-color,
.input-radio {
  &:checked + span,
  &:hover + span {
    border: 2px solid $gray-darker;
  }
}
.radio-label {
  @include box-shadow;
  background: white;
  display: inline-block;
  padding: 0.125rem $small-space;
  font-weight: 600;
  border: 2px solid white;
}
.product-actions {
  .control-label {
    width: 100%;
    margin-bottom: 0.375rem;
    display: block;
    text-align:right;
  }
  .product-add-to-cart {
    padding:30px 0;
  }
  .product-add-to-cart.variants {
    padding:0!important;
  }
  .add-to-cart {
    height: 50px;
    font-size:18px;
    text-transform:none;
    background:$brand-secondary;
    box-shadow:none;
    border-radius:10px;
    letter-spacing:0.1px;
    padding:5px 18px;
    line-height:1.25;
    img {
      margin-right:15px;
      margin-top:-3px;
    }
    .material-icons {
      line-height: inherit;
    }
  }
}
.product-quantity {
  display: flex;
  align-items:center;
  justify-content:flex-start;
  flex-wrap:wrap;

  .add {
    display:flex;
    align-items:center;
    justify-content:flex-end;
    margin-left:12px;
    padding:10px 0;
  }
  .qty {
    display:flex;
    align-items:center;
    justify-content:flex-end;
    background:#e2e2e2;
    border-radius:10px;

    .btn {
      padding:6px;
      background: #d0d0d0;
      color:#494949;
      margin-top:13px;
    }

    .input-group {
      display:flex;
      width:auto;
      box-shadow:none;
      background:#d0d0d0;
      border-radius:10px;
      .input-group-btn {

        width:100%;
      }
    }
  }

  #quantity_wanted {
    color: #494949;
    background-color: #e2e2e2;
    height: 50px;
    width: 40px;
    text-align:center;
    border:none;
    font-size:18px;
    padding:0;
  }
  .input-group-btn-vertical {
    width: auto;
    .btn {
      padding: 0.5rem 0.6875rem;
      i {
        font-size: 1rem;
        top: 0.125rem;
        left: 0.1875rem;
      }
    }
  }
  .btn-touchspin {
    height: 1.438rem;
  }
}
.product-discounts {
  //margin-bottom: 1.5rem;
  > .product-discounts-title {
    font-weight: normal;
    font-size: $font-size-sm;
  }
  > .table-product-discounts {
    thead tr th {
      width: 33%;
      padding: $small-space $medium-space;
      background: white;
      border: 0.3125rem $gray-light solid;
      text-align: center;
    }
    tbody tr {
      background: $gray-lighter;
      &:nth-of-type(even) {
        background: white;
      }
      td {
        padding: $small-space $medium-space;
        text-align: center;
        border: 0.3125rem $gray-light solid;
      }
    }
  }
}
.product-prices {
  //margin-top: $medium-space;

  .tax-shipping-delivery-label {
    font-size: 0.8125rem;
    color: $gray;
  }
}
.product-discount {
  color: $gray;
  .regular-price {
    text-decoration: line-through;
    font-size:20px;
    color:#939393;
    font-weight: 800;
    letter-spacing:0.2px;
  }
}
.has-discount {
  &.product-price, p {
    color: $brand-secondary;
    font-size:29px;
    font-weight:800;
    letter-spacing:0.3px;
  }
  .discount {
    background: $brand-secondary;
    color: white;
    font-weight: 600;
    padding: 0.3125rem $small-space;
    font-size: $font-size-base;
    margin-left: $small-space;
    text-transform: uppercase;
    display: inline-block;
  }
}
.product-unit-price {
  font-size: $font-size-xs;
  margin-bottom: 0;
}
.tabs {
  margin-bottom:45px;
  .tab-pane {
    padding-top: $medium-space;

    p {
      color:#000;
    }
  }
  .nav-tabs {
    border: none;
    .nav-link {
      color: #919191;
      border: 0 solid transparent;
      font-weight:400;
      font-size:14px;
      letter-spacing:0.4px;
      margin-right:16px;
      padding:0;
      &.active {
        border: none;
        font-weight:600;
        border-bottom: $brand-primary 2px solid;
        color: $brand-primary;
      }
      &:hover {
        border: none;
        border-bottom: $brand-primary 2px solid;
      }
    }
    .nav-item {
      float: left;
      margin-bottom: -0.125rem;
    }
  }
}
.product-cover {
  margin-bottom: $medium-space;
  position: relative;
  img {
    //@include box-shadow;
    //background: white;
  }
  .layer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: white;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    background: rgba(255,255,255,0.7);
    text-align: center;
    cursor: pointer;
    @include transition(opacity .7s ease-in-out);
    &:hover {
      opacity: 1;
    }
    .zoom-in {
      font-size: 6.25rem;
      color: $gray;
    }
  }
}

#blockcart-modal {
  .modal-body {
    .total-price {
      margin-bottom:10px;
    }
  }
}

#product-modal {
  .modal-content {
    background: transparent;
    border: none;
    padding: 0;
    .modal-body {
      display: flex;
      margin-left: -30%;
      .product-cover-modal {
        background: white;
        max-width:700px;
      }
      .image-caption {
        background: white;
        //width: 800px;
        width: 700px;
        padding: 0.625rem 1.25rem;
        border-top: $gray-light 1px solid;
        p {
          margin-bottom: 0;
        }
      }
      .thumbnails {
        position: relative;
      }
      .mask {
        position: relative;
        overflow: hidden;
        max-height: 49.38rem;
        margin-top: 2.188rem;
        z-index: 1;
        &.nomargin {
          margin-top: 0;
        }
      }
      .product-images {
        margin-left: $extra-large-space;
        li.thumb-container {
          border:none;
          margin:0;
        }
        img {
          width: 7.25rem;
          margin-bottom:10px;
          cursor: pointer;
          background: white;
          &:hover {
            border: $brand-primary 1px solid;
          }
        }
      }
      .arrows {
        height: 100%;
        width: 100%;
        text-align: center;
        position: absolute;
        top: 0;
        color: white;
        right: $large-space;
        z-index: 0;
        .arrow-up {
          position: absolute;
          top: -2rem;
          opacity: 0.2;
        }
        .arrow-down {
          position: absolute;
          bottom: -2rem;
        }
        i {
          font-size: 6.25rem;
          display: inline;
        }
        cursor: pointer;
      }
    }
  }
}
#blockcart-modal {
  padding-right:0;
  .modal-header {
    background:#fff;
  }
  button.close {
    margin-top:6px;
    font-size:16px;
  }
  .modal-body {
    background: #fff;
    padding: 15px;
    display:flex;
    align-items:center;
    display:-webkit-flex;
    -webkit-flex-wrap:wrap;

    .modal-center {
      display:flex;
      flex-direction:column;
      justify-content:space-between;
    }

    .product-image {
      margin:0 auto;
    }

    .product-price {
      font-weight:600;
      font-size:16px;
    }
    .regular-price {
      text-decoration: line-through;
      font-weight: 400;
      font-size:15px;
      margin-left:2px;
    }

    .btn-block {
      padding:0.5rem;
      text-transform:none;
      font-weight:400;
    }
  }
  .modal-dialog {
    //max-width: 1140px;
    max-width: 900px;
    width: 100%;
  }
  .modal-title {
    font-weight: 400;
    color: #000;
    font-size: 18px;
    i.material-icons {
      margin-right: 5px;
      margin-top:-6px;
    }
  }
  .product-name {
    //color: $brand-primary;
    color: #555454;
    font-size: 1.125rem;
    font-weight:400;
  }
  .cart-products-count {
    font-size: 1.125rem;
  }
  .cart-content {
    padding-left: $extra-large-space;
    .cart-content-btn {
      display: inline-flex;
      button {
        margin-right: 0.3125rem;
        border:1px solid #dfdcdc;

        &:focus {
          outline:none;
        }
      }
      .btn {
        white-space: inherit;
      }
    }
  }
  .divide-right {
    border-right: $gray 1px solid;
  }
}
.product-images {
  > li.thumb-container, li.slick-slide {
    display: inline;
    width:60px;
    height:60px;
    border:1px solid #ccc;
    border-radius:5px;
    cursor: pointer;
    margin:0 4px;
    overflow:hidden;
    position:relative;
    &.selected,
    &:hover {
      border: 1px solid $brand-primary;

      .layer-thumb {
        transition:1s;
        display:block;
      }
    }
    > .thumb {
      .images-container & {
        margin-right: 0.8125rem;
      }
    }
  }

  .slick-next, .slick-prev {
    width:12px;
    height:16px;
    margin-top:0;
    top:30px;
    border:none;
  }
  .slick-next {
    right:-28px;

    &:before {
      content:"";
      background-image:url(../img/right-arrow-product.svg);
      background-position:center;
      background-repeat:no-repeat;
      width:12px;
      height:100%;
      display:block;
    }
  }
  .slick-prev {
    left:-28px;

    &:before {
      content:"";
      background-image:url(../img/left-arrow-product.svg);
      background-position:center;
      background-repeat:no-repeat;
      width:12px;
      height:100%;
      display:block;
    }
  }

  .layer-thumb {
    display:none;
    background-color: rgba(62, 69, 144, 0.7);
    background-image:url(../img/zoom-product.svg);
    background-position:center;
    background-repeat:no-repeat;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    text-align: center;
    cursor: pointer;
    z-index:2;
    pointer-events: none;
    transition:1s;
  }
}
#main {
  .images-container {
    .slick-slider {
      margin-bottom:15px!important;
    }

    .js-qv-mask {
      padding:0 40px;
      white-space: nowrap;
      overflow: hidden;
      &.scroll {
        width: calc(100% - 60px);
        margin: 0 auto;
      }
    }
  }
}
.scroll-box-arrows {
  display: none;
  &.scroll {
    display: block;
  }
  i {
    position: absolute;
    bottom: 1.625rem;
    height: 100px;
    line-height: 100px;
    cursor: pointer;
  }
  .left {
    left: 0;
  }
  .right {
    right: 0;
  }
}
#product-availability {
  //margin-top: 0.625rem;
  margin-right:14px;
  display: inline-block;
  font-size:16px;
  img {
    margin-right:3px;
  }
  .product-available {
    color: $brand-success;
  }
  .product-onorder {
    color: $brand-secondary;
  }
  .product-unavailable {
    color:red;
  }
}
#product-details {
  .label {
    font-size: $font-size-base;
    color: $gray-darker;
    font-weight: bold;
  }
}
.product-features {
  //margin-top: $medium-space;
  //margin-left: 0.3125rem;
  > dl.data-sheet {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    dd.value,
    dt.name {
      flex: 1 0 40%;
      font-weight: normal;
      background: $gray-light;
      padding: $small-space;
      margin-right: $small-space;
      min-height: 2.5rem;
      word-break: normal;
      &:nth-of-type(even) {
        background: $gray-lighter;
      }
    }
    dt.name {
      text-transform: capitalize;
    }
  }
}
.product-variants {
  > .product-variants-item {
    margin: 10px 0;
    select {
      background-color: transparent;
      width: auto;
      padding-right: 1.875rem;
      float:right;
      @include box-shadow-light;
      outline:none;
      &:focus {
        outline:none!important;
        //background:transparent!important;
        //background-color:transparent!important;
      }
      option {
        background:transparent;
        &:focus {
          outline:none;
          background:transparent;
        }
      }
    }
    ul {
      float:right;
      margin-bottom:0;
    }
    ul li {
      margin-left: $small-space;
    }
    .color {
      margin-left: 0;
      margin-top: 0;
    }
  }
}
.product-flags {
  position: absolute;
  top: 0;
  width: 100%;
}
li.product-flag {
    display: block;
    position: absolute;
    background: $brand-primary;
    font-weight: 700;
    padding: 0.3125rem 0.4375rem;
    text-transform: uppercase;
    color: white;
    margin-top: $small-space;
    z-index: 2;
    &.online-only{
      top: 25rem;
      right: 0;
      font-size: $font-size-xs;
      &::before{
        content:"\E30A";
        font-family: 'Material Icons';
        vertical-align: middle;
        margin: 0.3125rem;
      }
    }
    &.on-sale {
      background: $brand-secondary;
      width: 100%;
      text-align: center;
      margin: 0;
      left:0;
      top:0;
    }
    &:not(.discount):not(.on-sale)~li.product-flag {
        margin-top: 3.75rem;
    }
  &.new {
    display:none;
  }
  &.discount {
    background:$brand-primary;
    font-size:12px;
    border-radius:3px;
    width:41px;
    height:20px;
    padding-top:0;
    margin-top:0;
    top:5px;
    left:5px;
  }
}

body#product {
  .images-container {
    li.product-flag {
      position: absolute;
      background: $brand-primary;
      font-weight: 700;
      padding: 0.3125rem 0.4375rem;
      text-transform: uppercase;
      color: white;
      margin-top: $small-space;
      z-index: 2;
      &.new {
        display:block;
        font-size:18px;
        right:6px;
        top:6px;
        margin-top:0;
        border-radius:3px;
        padding:6px 12px;
      }
      &.discount {
        background:#ff6d1e;
        font-size:18px;
        width:60px;
        height:60px;
        padding-top:20px;
        padding-left:10px;
        margin-top:0;
        top:5px;
        left:5px;
      }
    }
  }
}

.product-customization {
  //margin: $extra-large-space 0;
  padding:15px 0;
  .product-customization-item {
    margin: $medium-space 0;
  }
  .product-message {
    background: $gray-light;
    border: none;
    width: 100%;
    height: 3.125rem;
    resize: none;
    padding: 0.625rem;
    &:focus {
      background-color: white;
      outline: 0.1875rem solid $brand-primary;
    }
  }
  .file-input {
    width: 100%;
    opacity: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
    height: 2.625rem;
    overflow: hidden;
    position: absolute;
  }

  .customization-message{
    margin-top: 20px;
    label {
      text-align:left;
    }
  }
  .custom-file {
    position: relative;
    background: $gray-light;
    width: 100%;
    height: 2.625rem;
    line-height: 2.625rem;
    text-indent: 0.625rem;
    display: block;
    color: $gray;
    margin-top: $medium-space;
    button {
      z-index: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  small {
    color: $gray;
  }
  button {
    white-space:normal;
  }
}
.product-pack {
  margin-top:-26px;
  .pack-product-container {
    display: flex;
    justify-content: flex-start;
    align-items:center;
    padding:5px;
    .thumb-mask {
      margin-right:5px;
    }
    .pack-product-name {
      font-size: 0.875rem;
      color: $gray;
    }
  }
}
.product-refresh {
  margin-top: $medium-space;
}
.social-sharing {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  ul {
    margin-bottom: 0;
  }
  li {
    @include box-shadow-light;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    display: inline-block;
    background-color: white;
    background-repeat: no-repeat;
    background-size: 2rem 2rem;
    background-position: 0.25rem 0.25rem;
    cursor: pointer;
    margin-left: 0.5rem;
    @include transition(all .2s ease-in);
    a {
      display: block;
      width: 100%;
      height: 100%;
      white-space: nowrap;
      text-indent: 100%;
      overflow: hidden;
      &:hover {
        color: transparent;
      }
    }
  }
}
.products-selection {
  margin-bottom: $medium-space;
  .title {
    color: $gray;
  }
}
#blockcart-modal .cart-content {
  .btn {
    margin-bottom: $small-space;
  }
}

.product-right {
  h2 {
    font-size:22px;
    font-weight:400;
    color:$brand-secondary;
    border-bottom:1px solid #d7d7d7;
    padding-bottom:5px;
    margin-bottom:0;
    text-transform:none;
  }
  .product-info {
    padding:10px 0;
    .left-info {
      .li-header {
        display:flex;
        align-items:center;
        justify-content:space-between;
        margin-bottom:10px;
      }
    }
    .right-info {
      .contact-rapid {
        p.title {
          text-transform:uppercase;
          border-bottom:1px solid #efefef;
          padding-bottom:10px;
          color:$brand-secondary;
        }

        .contact-phone, .contact-email {
          display:flex;
          align-items:center;
          i {
            font-size:20px;
            margin-right:10px;
          }
        }
        .contact-phone {
          margin-bottom:10px;
          i {
            margin-left:3px;
            margin-right:13px;
          }
        }
        .contact-email {
          font-size:14px;
        }
      }
    }
  }
}

//.product-right {
//  h1 {
//    font-size:22px;
//    font-weight:400;
//    color:#000;
//    border-bottom:1px solid #d7d7d7;
//    margin-bottom:5px;
//    text-transform:none;
//    letter-spacing:0.4px;
//    &:after {
//      content:"";
//      display:block;
//      max-width:197px;
//      margin-top:19px;
//      margin-bottom:-1px;
//      border-bottom:4px solid $brand-primary;
//    }
//  }
//
//  .product-info {
//    display:flex;
//    align-items:center;
//    justify-content:space-between;
//    flex-wrap:wrap;
//    border-bottom:1px solid #d7d7d7;
//    padding-bottom:5px;
//    .left-info {
//      display:flex;
//      align-items:center;
//      .sku {
//        font-size:16px;
//        font-weight:800;
//        letter-spacing:0.4px;
//        color:#010102;
//        margin-right:35px;
//      }
//    }
//  }
//
//  .product-prices-wrapper {
//    display:flex;
//    align-items:center;
//    justify-content:space-between;
//    flex-wrap:wrap;
//    padding:34px 0;
//    border-bottom:1px solid #d7d7d7;
//
//    .phone-orders {
//      display:flex;
//      align-items:center;
//      //padding:10px 15px 10px 0;
//      padding:10px 10px 10px 0;
//      img {
//        margin-right:8px;
//      }
//      a {
//        font-size:15px;
//        letter-spacing:0.4px;
//        color:#2d2d2d;
//        line-height:1.3;
//        span.phone-number {
//          color:#ff6d1e;
//          font-size:21px;
//          letter-spacing:1.2px;
//        }
//      }
//    }
//  }
//}

@include media-breakpoint-down(md) {
  .product-cover {
    img {
      width: 100%;
    }
  }
  #product-modal .modal-content .modal-body {
    flex-direction: column;
    margin-left: 0;
    img.product-cover-modal {
      width: 100%;
    }
    .arrows {
      display: none;
    }
  }
  #product-modal .modal-content .modal-body .image-caption {
    width: 100%;
  }
  #blockcart-modal {
    .modal-dialog {
      width: calc(100% - 20px);
    }
    .modal-body {
      padding: 1.875rem;
      .total-price {
        margin-top:10px;
        margin-bottom:10px;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  #blockcart-modal {
    .divide-right {
      border-right: none;
    }
    .modal-body {
      padding: 1rem;
    }
  }
  .product-actions {
    .control-label {
      text-align:left;
    }
    .product-variants > .product-variants-item select{
      float:none!important;
    }
    .product-variants > .product-variants-item ul {
      float:none!important;
      li {
        margin-left:0;
        margin-right:$small-space;
      }
    }
  }

  .product-cover {
    .slick-dots {
      bottom:-5px;
      li button {
        &:before {
          font-size:14px;
          color:$brand-primary;
        }
        &.slick-active button {
          &:before {
            color:$brand-secondary;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .product-actions {
    .product-variants > .product-variants-item select {
      width:100%;
    }
  }
}