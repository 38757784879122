.carousel {
  margin-top:-77px;
  @media (max-width: 992px) {
    margin-top:25px;
  }
  @media (max-width: 767px) {
    //margin-top:-25px;
  }
  .direction {
    z-index: auto;
  }
  .carousel-inner {
    height: 471px;
    @media (max-width: 992px) {
      height: auto;
    }
  }
  .carousel-item {
    height: 100%;

    figure {
      display:flex;
      @media (max-width: 992px) {
        display: block;
      }
    }

    img {
      object-fit:cover;
      @media (max-width: 992px) {
        max-width: 100%;
        height: auto;
        object-fit:cover;
      }
      @media (min-width: 768px) {
      width: 100%;
      margin-left: 0;
    }
    }
    .caption {
      display:none;
      //position: absolute;
      //@media (min-width: 768px) {
      //bottom: 28px;
      //left: 90px;
      //}
      //@media (max-width: 767px) {
      //  bottom: 5px;
      //  left: 40px;
      //}
      //color: white;
      //max-width: 340px;
      //.caption-description p {
      //  color: white;
      //}
    }
    figure {
      @media (max-width: 767px) {
        margin: 0;
      }
    }
  }
  .carousel-control {
    opacity: 1;
    .icon-next,
    .icon-prev {
      &::before {
        content: "";
      }
      i {
        font-size: 3.125rem;
        color: white;
      }
      &:hover {
        i {
          color: $brand-primary;
        }
      }
    }
    .icon-prev {
      left: 1rem;
    }
    .icon-next {
      right: 3rem;
    }
    &.left,
    &.right {
      background: none;
    }
  }

  .carousel-indicators-wrapper {
    display:flex;
    align-items:center;
    justify-content:center;

    .carousel-indicators {
      display: flex;
      left:auto;
      bottom:85px;
      margin:0 auto;
      text-align:center;
      padding:9px 15px;
      width:auto;
      background: rgba(255, 255, 255, 0.48);
      border-radius:5px;

      li {
        width:10px;
        height:10px;
        border:none;
        margin:0 5px;
        color:#fff;
        background-color:#fff;
        &.active {
          color:$brand-primary;
          background-color:$brand-primary;
        }
      }

      @media (max-width: 992px) {
        bottom:40px;
      }
      @media (max-width: 767px) {
        bottom:20px;
      }
    }
  }

}
