.block_newsletter {
  font-size: $font-size-sm;
  p {
    padding-top: $small-space;
  }
  form {
    .input-wrapper {
      overflow: hidden;
    }
    input[type=text] {
      padding: 11px;
      background:#fff!important;
      border:none!important;
      border-radius:5px;
      width: 100%;
    }
    input {
      height: 39px;
      box-shadow: none;
    }
  }
  //margin-bottom: 0.625rem;
}
.linklist {
  .blockcms-title a {
    color:$gray-darker;
  }
}
.account-list a {
  color: $gray;
  &:hover {
    color: $brand-primary;
  }
}
.blockcms-title,
.myaccount-title,
.myaccount-title a, {
  font-weight: 700;
  font-size: $base-font-size;
}
.block-social {
  position:fixed;
  top:calc(50% - 48px);
  right:0;
  padding:0;
  width:48px;

  ul {
    margin-bottom:0;

    li {
      height: 48px;
      width: 48px;
      //background-color: $gray-light;
      //background-repeat: no-repeat;
      display: block;
      cursor: pointer;
      margin:0!important;
      &:hover {
        background-color: $brand-primary;
      }
      a {
        display: block;
        height: 100%;
        white-space: nowrap;
        text-indent: 100%;
        overflow: hidden;
        &:hover {
          color: transparent;
        }
      }
    }
  }
}

.facebook {
  background-color: #3b5998;
  a {
    &:before {
      content:'\f39e';
      font-family:'Font Awesome 5 Brands';
      position: absolute;
      display:block;
      font-size:22px;
      color:#fff;
      margin-left:-30px;
      margin-top:13px;
    }
  }


}
.rss {
  background-color: #848484;
  a {
    &:before {
      content:'\f0e0';
      font-family:'Font Awesome 5 Free';
      position: absolute;
      display:block;
      font-size:22px;
      color:#fff;
      margin-left:-35px;
      margin-top:14px;
    }
  }

}
.twitter {
  background-image: url(../img/twitter.svg);
  &::before{
    content:"";
    background-image: url(../img/twitter-blue.svg);
  }
  &.icon-gray {
    background-image: url(../img/twitter-gray.svg);
    &:hover {
      background-image: url(../img/twitter-blue.svg);
    }
  }
}
.youtube {
  background-image: url(../img/youtube.svg);
}
.googleplus {
  background-image: url(../img/gplus.svg);
  &::before{
    content:"";
    background-image: url(../img/gplus-blue.svg);
  }
  &.icon-gray {
    background-image: url(../img/gplus-gray.svg);
    &:hover {
      background-image: url(../img/gplus-blue.svg);
    }
  }
}
#block_myaccount_infos{
  .myaccount-title a{
    color:$gray-darker;
  }
}
.pinterest {
  background-image: url(../img/pinterest.svg);
  &::before{
    content:"";
    background-image: url(../img/pinterest-blue.svg);
  }
  &.icon-gray {
    background-image: url(../img/pinterest-gray.svg);
    &:hover {
      background-image: url(../img/pinterest-blue.svg);
    }
  }
}
.vimeo {
  background-image: url(../img/vimeo.svg);
}
.instagram {
  background-image: url(../img/instagram.svg);
}
.footer-container {
  //margin-top: $medium-space;
  padding-top: 45px;
  overflow: hidden;
  background:#fff;
  li a {
    color: $brand-secondary;
    cursor: pointer;
    font-size: 14px;
    letter-spacing:0.3px;
    &:hover {
      color: $brand-primary;
      transition:.3s;
    }
  }

  .linoleum-mocheta-info {
    .footer-logo {
      margin-bottom:10px;
    }
    p {
      color:$brand-secondary;
      font-size:14px;
    }
    a {
      font-size:14px;
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      .partner-logo {
        margin-right: 5px;
      }
    }
  }

  .links {
    //padding:0;

    .footer-links, .myaccount-title, .block-contact-title {
      color:$brand-secondary;
      text-transform:none;
      font-weight:700;
      letter-spacing:0.3px;
      font-size:20px;
      margin-bottom:10px;
    }

    li {
      margin-bottom:5px;
    }

    .collapse {
      display: inherit;
    }
  }

  .block-contact {
    p, a {
      color:$brand-secondary;
      font-size:14px;
      margin-bottom:10px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .block_newsletter {
    input[type=text] {
      margin-bottom:10px;
    }
  }
  .footer-container {
    box-shadow: none;
    margin-top: 0;
    .wrapper {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .linoleum-mocheta-info {
      margin-bottom:20px;
    }
    .links {
      .h3 {
        line-height: 1.5;
        font-size: 1rem;
      }
      .title {
        //padding: 0.625rem;
        padding: 10px 0;
        cursor: pointer;
        .collapse-icons .remove {
          display: none;
        }
      }
      .title[aria-expanded="true"] .collapse-icons {
        .add {
          display: none;
        }
        .remove {
          display: block;
        }
      }
      .navbar-toggler {
        display: inline-block;
        padding: 0;
        color:$brand-secondary;
      }
      .collapse {
        display: none;
        &.in {
          display: block;
        }
      }
      ul {
        background-color: $gray-lighter;
        margin-bottom: 0;
        > li {
          padding: 10px 0;
          margin-bottom:0;
          background:#fff;
          border-bottom: 1px solid #eff1f7;
          &:last-child {
            border-bottom:none;
            margin-bottom:20px;
          }
        }
      }
      #contact-info {
        padding:0;
      }
    }
  }
}

#contact-info {
  padding:10px;
}

@include media-breakpoint-down(md) {
  .block-social {
    text-align: center;
  }
  //.block-contact {
  //  padding-left: 1.5rem;
  //}
}

.displayFooterAfter {
  padding: 10px 0 15px;
  li {
    float:left;
    width:12%;
    a {
      font-size:10px;
      text-decoration:none;
      color:#97979f;
      text-transform:none;
      white-space:nowrap;
    }
  }
}

.alexh-footer {
  padding:15px;
  background:#fff;
  .copyright-text {
    padding:0;
    p {
      color:$brand-secondary;
      font-size:14px;
      margin:15px 0;
    }
  }
}
